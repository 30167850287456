import './modal-widow.css'
import canselBtn from '../assets/icon/cancel.png'
import { useForm } from "react-hook-form";
import axios from "axios";
function ModalWidow({getModalState,carUrl}) {
    const {register, getValues, setValue} = useForm();
    function getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                // Перевірте, чи починається cookie із потрібного імені
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }
    const csrftoken = getCookie('csrftoken');

    axios.defaults.headers.post['X-CSRFToken'] = csrftoken;

    const sendDataOnTg = (data,e) => {
        // e.preventDefault();
        if(getValues("userName"),getValues('userPhone') ,getValues('userEmail') ,getValues('userComment')){
            let message = ''
            if(carUrl){
                message = `Купівля машини ${carUrl}. Повідомлення ${getValues('userComment')}`
            }else{
                message = ` ${getValues('userComment')}`
            }

            axios.post('/api/tg-send/', {
                "name": getValues("userName"),
                "phone": getValues('userPhone'),
                "email": getValues('userEmail'),
                "message":message ,
            }).then(function (response) {
                if (response && response.status === 200){
                    getModalState(false)
                }
            }).catch(function (error) {
                console.log(error);
            });
        }else{
            alert("Заповніть усі обов'язкові поля поля *")
        }
    }


    return (
        <div className="backdrop">
                <div className="modal">
                    <button  type="button" className="modal-close " onClick={()=>{
                        getModalState(false)
                    }}>
                        <img className="close-icon" src={canselBtn} alt=""/>
                    </button>
                    <h2 className="modal-title">Залиште свої дані, ми вам передзвонимо</h2>
                    <form>
                        <div className="form-field">
                            <label htmlFor="name" className="input-title">Ім'я*</label>
                            <div className="icon-container">
                                <input
                                    className="input-form"
                                    type="text"
                                    id="name"
                                    name="user_name"
                                    {...register("userName")}
                                />
                            </div>
                        </div>
                        <div className="form-field">
                            <label htmlFor="tel" className="input-title">Телефон*</label>
                            <div className="icon-container">
                                <input
                                    className="input-form"
                                    type="tel"
                                    id="tel"
                                    name="phone_number"
                                    {...register("userPhone")}
                                />

                            </div>
                        </div>
                        <div className="form-field">
                            <label className="input-title" htmlFor="email">Пошта*</label>
                            <div className="icon-container">
                                <input className="input-form" type="email" id="email" name="email"  {...register("userEmail")}/>
                            </div>
                        </div>
                        <div className="form-field-comment">
                            <label htmlFor="comment" className="input-title">Повідомлення</label>
                            <textarea
                                className="input-form input-comment"
                                name="comment"
                                id="comment"
                                placeholder="Повідомлення"
                                {...register("userComment")}
                            ></textarea>
                        </div>

                        <button  className="btn form-button" onClick={sendDataOnTg}>
                            <span>Замовити дзвінок</span>
                        </button>
                    </form>
                </div>
        </div>
    );
}

export default ModalWidow;