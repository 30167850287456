import './App.css';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import CarDetails from "./car-details/car-details";
import HomePage from "./home-page/home-page";
import { useEffect, useState } from "react";
import AllCarPage from "./allCarPage/allCarPage";
import axios from "axios";
import Header from "./header/header";
import Footer from "./footer/footer";


function App() {
    const [modalOpen, setModalOpen] = useState(false);
    const [component, setComponent] = useState('header');
    const [currentCarId, setCurrentCarId] = useState(0);
    const [cars, setCars] = useState([]);

    const getCars = () => {
        axios.get('/api/products')
            .then(function (res) {
                setCars(res.data);
            })
    }
    useEffect(() => {
        getCars();
    }, [])
    const getCurrentCarId = (carId) => {
        setCurrentCarId(carId)
    }
    const getModalState = (boolean) => {
        setModalOpen(boolean);
    }
    const getComponent = (el) => {
        setComponent(el);
    }
    const scrollToComponent = (el) => {
        if (component !== "") {
            document.getElementById(`${el}__scroll`).scrollIntoView({
                behavior: 'smooth', block: "start"
            });
        }
    }


    useEffect(() => {
        scrollToComponent(component);
        setComponent('');
    }, [component])
    return (
        <div className="App">
            <Header getComponent={getComponent} getModalState={getModalState}/>
            <Routes>
                    <Route path='/' element={<HomePage cars={cars} getCurrentCarId={getCurrentCarId} getComponent={getComponent} getModalState={getModalState} modalOpen={modalOpen} />} />
                    <Route path='/:car-details/:id' element={<CarDetails currentCarId={currentCarId} getComponent={getComponent} getModalState={getModalState} modalOpen={modalOpen} />} />
                    <Route path='/car' element={<AllCarPage cars={cars} getCurrentCarId={getCurrentCarId} getComponent={getComponent} modalOpen={modalOpen} getModalState={getModalState} />} />
                </Routes>
            <Footer/>
        </div>
    );
}

export default App;
