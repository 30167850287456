import React from 'react';
import reviewIcon from "../assets/icon/quotes.png";

function Review({comment}) {
    return (
        <div>
            <div className="reviews__item">
                <div className='reviews_name__title'>
                    <img src={reviewIcon} alt="" className="reviews__icon"/>
                    <p className="reviews__username">
                        {comment.name}
                    </p>
                </div>

                <p className="reviews__text" id="style-3">
                    {comment.comment}
                </p>
            </div>
        </div>
    );
}

export default Review;
