import './benefits.css';

function Benefits() {

    return (
        <>
                <div className='benefits' id='benefits__scroll'>
                    <div className="benefits__container container">
                        <h2 className="benefits__title">
                            Які переваги мають автомобілі з США
                        </h2>
                        <div className="benefits__row">
                            <div className="benefits__item">
                                <h3 className="benefits__item__title">
                                    Максимальна комплектація
                                </h3>
                                <p className="benefits__item_info">
                                    Американські водії частіше за все віддають перевагу «багатим» комплектаціям, а іноді –
                                    навіть ексклюзивним, таким, які не зустрінеш у Європі
                                </p>
                            </div>
                            <div className="benefits__item">
                                <h3 className="benefits__item__title">
                                    Якісна збірка
                                </h3>
                                <p className="benefits__item_info">
                                    Більша частина американських авто збирається безпосередньо у США та Канаді – країнах, де
                                    якості та стандартам надають особливої уваги
                                </p>
                            </div>
                            <div className="benefits__item">
                                <h3 className="benefits__item__title">
                                    Повна прозора історія
                                </h3>
                                <p className="benefits__item_info">
                                    Кожне авто з США  має історію сервісу та основних подій, пов’язаних з ним
                                </p>
                            </div>
                            <div className="benefits__item">
                                <h3 className="benefits__item__title">
                                    Регулярне обслуговування
                                </h3>
                                <p className="benefits__item_info">
                                    Більшість авто у Америці – лізингові, тому їх власники зобов’язані регулярно проходити
                                    сервісне обслуговування на офіційній СТО
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
        </>
    );
}

export default Benefits;