import React, {useEffect, useState} from 'react';

import {Link} from "react-router-dom";
import axios from "axios";

function Car({car, getCurrentCarId, id}) {
    const [currentCar, setCurrentCar] = useState([])
    useEffect(() => {

    }, [currentCar])
    useEffect(() => {
        axios.get(`api/products-img/${id}/`)
            .then(function (res) {
                setCurrentCar(res.data);
            })
    }, [id,car])

    return (
        <div className="actual-car__item">
            <div className="actual-car__item__img__box">
                <img src={currentCar[0]?.img} alt="car-img" className="actual-car__img" loading="lazy"/>
            </div>
            <div className="actual-car__info">
                <h3 className="actual-car__name">
                    {car.name} {car.rik}
                </h3>
                <p className="actual-car__price">
                    {car.price}$
                </p>
                <div className="actual-car__details">
                    <div className="actual-car__details__item">
                        <p className="actual-car__details__info">
                            <p className="acrtual-car__details_info">
                                Пробіг
                            </p>
                            <p>
                                {car.probig}км
                            </p>
                        </p>
                        <p className="actual-car__details__info">
                            <p className="acrtual-car__details_info">
                                Двигун
                            </p>
                            <p>
                                {car.motor}л
                            </p>
                        </p>
                        <p className="actual-car__details__info">
                            <p className="acrtual-car__details_info">
                                Коробка передач
                            </p>
                            <p>
                                {car.korobka}
                            </p>
                        </p>
                    </div>
                </div>
            </div>

            <div className="actual-car__btn-box">
                <Link to = {`/car-details/${car.id}`}>
                    <button className="car__price-button" onClick={() => {
                        getCurrentCarId(car.id)
                    }}>Купити
                    </button>
                </Link>
                <Link to = {`/car-details/${car.id}`}>
                    <button className="car__price-button" onClick={() => {
                        getCurrentCarId(car.id)
                    }}>Детальніше
                    </button>
                </Link>
            </div>
        </div>
    );
}

export default Car;


