import Header from "../header/header";
import Hero from "../hero/hero";
import Benefits from "../benefits/benefits";
import CarPriceCalculator from "../car-price-calculator/car-price-calculator";
import ActualCar from "../actual-car/actual-car";
import Callback from "../callback/callback";
import Auctions from "../auctions/auctions";
import Footer from "../footer/footer";
import ModalWidow from "../modal-widow/modal-widow";
import Reviews from "../reviews/reviews";


function HomePage({cars, getComponent ,getModalState ,modalOpen,getCurrentCarId}) {




    const openModalWindow =()=>{
        if(modalOpen){
            return <ModalWidow getModalState={getModalState}/>
        }else{
            getModalState(false)
            return '';
        }
    }


    return (
        <div>
            {openModalWindow()}
            <Hero  getModalState={getModalState} getComponent={getComponent}/>
            <Benefits getComponent={getComponent}/>
            <CarPriceCalculator cars={cars}/>

            <ActualCar getCurrentCarId={getCurrentCarId} cars={cars}/>
            <Callback getModalState={getModalState}/>
            <Auctions/>
            <Reviews/>
        </div>
    );
}

export default HomePage;