import './actual-car.css';

import Car from "./car";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
const ActualCar = ({getCurrentCarId,cars}) => {

        return (
            <div className='actual-car' id="actual-car__scroll">
                <div className="actual-car__container container">
                    <h2 className="actual-car__title">
                        Автомобілі в наявності
                    </h2>
                    <div className="actual-car__row">
                        <i  className="icon-arrow-long-left review-swiper-button-prev"></i>
                        <Swiper
                            slidesPerView={3}
                            spaceBetween={30}
                            freeMode={true}
                            navigation={{
                                nextEl: '.review-swiper-button-next',
                                prevEl: '.review-swiper-button-prev',
                            }}
                            rewind={true}
                            modules={[ Navigation, Pagination ]}
                            pagination={{
                                dynamicBullets: true,
                            }}
                            className="mySwiper actual_car_swpier"
                            breakpoints={{
                                // when window width is >= 640px
                                1200: {

                                    slidesPerView:4,
                                },
                                780: {

                                    slidesPerView: 3,
                                },
                                // when window width is >= 768px
                                500: {
                                    slidesPerView:2,
                                },
                                320: {
                                    slidesPerView:1,
                                },
                            }}>
                            {cars.map(car =>
                                <SwiperSlide>
                                    <Car id={car.id} car = {car} getCurrentCarId={getCurrentCarId}/>
                                </SwiperSlide>
                            )}
                        </Swiper>
                        <i className="icon-arrow-long-right review-swiper-button-next"></i>
                    </div>
                </div>
            </div>
        );

}

export default ActualCar;