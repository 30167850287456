import './car-price-calculator.css';
import { useEffect, useState } from "react";
import canselBtn from '../assets/icon/cancel.png'
import axios from "axios";
import { logDOM } from "@testing-library/react";

function CarPriceCalculator({ cars }) {
    const [userPhone, setUserPhone] = useState(0);
    const [carPrice, setCarPrice] = useState(0);
    const [selectedYear, setSelectedYear] = useState('');
    const [selectedCarName, setSelectedCarName] = useState('');
    const [selectedEngine, setSelectedEngine] = useState('');
    const [selectedModel, setSelectedModel] = useState('');

    const [selectedYearFrom, setSelectedYearFrom] = useState('');
    const [selectedYearTo, setSelectedYearTo] = useState('');
    const [selectedEngineFrom, setSelectedEngineFrom] = useState('');
    const [selectedEngineTo, setSelectedEngineTo] = useState('');
    function getCookie(name) {
        let cookieValue = null;
        if (document.cookie && document.cookie !== '') {
            const cookies = document.cookie.split(';');
            for (let i = 0; i < cookies.length; i++) {
                const cookie = cookies[i].trim();
                // Перевірте, чи починається cookie із потрібного імені
                if (cookie.substring(0, name.length + 1) === (name + '=')) {
                    cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
                    break;
                }
            }
        }
        return cookieValue;
    }
    const csrftoken = getCookie('csrftoken');

    axios.defaults.headers.post['X-CSRFToken'] = csrftoken;

    const filteredCars = cars.filter((car) => {
        const isYearInRange = (selectedYearFrom, selectedYearTo, carYear) => {
            return (
                (selectedYearFrom === '' || carYear >= parseInt(selectedYearFrom)) &&
                (selectedYearTo === '' || carYear <= parseInt(selectedYearTo))
            );
        };
        const isEngineVolumeMatch = (selectedYearFrom, selectedYearTo, carEngine) => {
            return (
                (selectedYearFrom === '' || carEngine >= parseInt(selectedYearFrom)) &&
                (selectedYearTo === '' || carEngine <= parseInt(selectedYearTo))
            );
        };
        return (
            isYearInRange(selectedYearFrom, selectedYearTo, car.rik) &&
            isEngineVolumeMatch(selectedEngineFrom, selectedEngineTo, car.motor) &&
            (selectedModel === '' || car.kyzov === selectedModel) &&
            (selectedCarName === '' || car.name === selectedCarName)

        );
    })

    const handleSelectedYearFromChange = event => {
        setSelectedYearFrom(event.target.value);
    };
    const handleSelectedYearToChange = event => {
        setSelectedYearTo(event.target.value);
    };

    const selectedEngineToChange = event => {
        setSelectedEngineTo(event.target.value);

    };
    const selectedEngineFromChange = event => {
        setSelectedEngineFrom(event.target.value);

    };

    const handleModelChange = event => {
        setSelectedModel(event.target.value);
    };
    const handleCarNameChange = event => {
        setSelectedCarName(event.target.value);
    };
    const handleInputChange = (e) => {
        setCarPrice(e.target.value)
    }
    const handlePhoneChange = (e) => {
        const numericValue = e.target.value.replace(/\D/g, '');
        setUserPhone(numericValue)
    }
    const validateInputValueOnKeyDown = (e) => {
        const keyCode = e.keyCode || e.which;
        const keyValue = String.fromCharCode(keyCode);
        const isNumeric = /^[0-9]*$/.test(keyValue);
        const isBackspace = keyCode === 8;

        if (!isNumeric && !isBackspace) {
            e.preventDefault();
        }
    };

    const sendDataOnTg = (data) => {
        if (selectedCarName, carPrice, selectedYearFrom, selectedYearTo, selectedModel, selectedEngine, userPhone) {
            let motor = '';
            let rik = "";
            if (selectedYearFrom === selectedYearTo) {
                rik = selectedYearFrom
            } else {
                rik = `Від ${selectedYearFrom} до ${selectedYearTo}`
            }

            if (selectedEngineFrom === selectedEngineTo) {
                motor = selectedYearFrom
            } else {
                motor = `Від ${selectedEngineFrom} до ${selectedEngineTo}`
            }
            axios.post('/api/tg-form/', {
                "name": selectedCarName,
                "price": carPrice,
                "rik": rik,
                "kyzov": selectedModel,
                "motor": motor,
                "phone": userPhone
            }).then(function (response) {

            }).catch(function (error) {
                console.log(error);
            });
        } else {
            alert("Оберіть та заповніть усі поля")
        }
    }

    useEffect(() => {

    }, [selectedYearFrom, selectedYearTo, selectedEngine, selectedModel, selectedCarName, carPrice, selectedEngineFrom, selectedEngineTo])



    const cancelBtn = (fn) => {

        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                className="select-container-cancel"
                onClick={() => fn('')}
            >
                <path
                    fill="#000000"
                    d="M16.707 4.707a1 1 0 0 0-1.414-1.414L10 8.586 4.707 3.293a1 1 0 0 0-1.414 1.414L8.586 10l-5.293 5.293a1 1 0 1 0 1.414 1.414L10 11.414l5.293 5.293a1 1 0 0 0 1.414-1.414L11.414 10l5.293-5.293Z"
                />
            </svg>
        )
    }

    const uniqueCarsName = Array.from(new Set(filteredCars.map((car) => car.name)));
    const uniqueOptionsName = uniqueCarsName.map((carName, index) => (
        <option className="option" key={index}>
            {carName}
        </option>
    ));
    const uniqueCarsModel = Array.from(new Set(filteredCars.map((car) => car.kyzov)));
    const uniqueOptionsModel = uniqueCarsModel.map((carModel, index) => (
        <option className="option" key={index}>
            {carModel}
        </option>
    ));
    const uniqueCarsYear = Array.from(new Set(filteredCars.map((car) => car.rik)));
    uniqueCarsYear.sort((a, b) => parseInt(a) - parseInt(b));

    const uniqueOptionsYear = uniqueCarsYear.map((carYear, index) => (
        <option className="option" key={index}>
            {carYear}
        </option>
    ));

    const uniqueCarsEngine = Array.from(new Set(filteredCars.map((car) => car.motor)));
    uniqueCarsEngine.sort((a, b) => parseInt(a) - parseInt(b));

    const uniqueOptionsEngine = uniqueCarsEngine.map((carEngine, index) => (
        <option className="option" key={index}>
            {carEngine}
        </option>
    ));
    return (
        <div className='car__price__calculator'>
            <div className="car__price__calculator__container container">
                <h2 className="car__price__calculator__title">
                    Розрахувати вартість авто з США
                </h2>
                <div className="car__price__calculator__body">
                    <div className="car__price__calculator__form">
                        <div className="car__price__calculator__form-item">
                            <div className="car__price__calculator__form-item-box">
                                <div className="select-container-box">

                                    <div className="select-container">
                                        <div className="option-container ">
                                            {cancelBtn(setSelectedCarName)}
                                            <select name="car_name" id="car_name"
                                                className="car__price__calculator-select" value={selectedCarName}
                                                onChange={handleCarNameChange}>
                                                <option className="option">
                                                    Оберіть марку авто
                                                </option>
                                                {uniqueOptionsName}
                                            </select>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="car__price__calculator__form-item">
                            <div className="car__price__calculator__form-item-box">
                                <div className="select-container-box">
                                    <h3 className="select-container-title">
                                        Бажаний бюджет:
                                    </h3>
                                    <div className="select-container" id='type'>
                                        <div className="select-insert">
                                            <input className="select__input-insert" type="text" id="input" onChange={handleInputChange} onKeyDown={validateInputValueOnKeyDown}/>
                                        </div>
                                    </div>
                                </div>
                                <div className="select-container-box">
                                    <h3 className="select-container-title">
                                        Кузов
                                    </h3>
                                    <div className="select-container" id='type'>
                                        <div className="select-container">

                                            <div className="option-container ">
                                                {cancelBtn(setSelectedModel)}
                                                <select value={selectedModel} onChange={handleModelChange}
                                                    name="car_name" id="car_name"
                                                    className="car__price__calculator-select">
                                                    <option className="option">
                                                        Оберіть тип кузову
                                                    </option>
                                                    {
                                                        uniqueOptionsModel
                                                    }
                                                </select>

                                            </div>

                                        </div>

                                    </div>
                                </div>


                            </div>
                        </div>
                        <div className="car__price__calculator__form-item">
                            <div className="car__price__calculator__form-item-box">

                                <div className="select-container-box">
                                    <h3 className="select-container-title">
                                        Рік випуску від
                                    </h3>
                                    <div className="select-container">
                                        <div className="option-container ">
                                            {cancelBtn(setSelectedYearFrom)}
                                            <select value={selectedYearFrom} onChange={handleSelectedYearFromChange} name="car_name"
                                                id="car_name"
                                                className="car__price__calculator-select">
                                                <option className="option">
                                                    Від
                                                </option>
                                                {
                                                    uniqueOptionsYear
                                                }
                                            </select>

                                        </div>

                                    </div>
                                </div>
                                <div className="select-container-box">
                                    <h3 className="select-container-title">
                                        Рік випуску до
                                    </h3>
                                    <div className="select-container">
                                        <div className="option-container ">
                                            {cancelBtn(setSelectedYearTo)}
                                            <select value={selectedYearTo} onChange={handleSelectedYearToChange} name="car_name"
                                                id="car_name"
                                                className="car__price__calculator-select">
                                                <option className="option">
                                                    До
                                                </option>
                                                {
                                                    uniqueOptionsYear
                                                }
                                            </select>

                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="car__price__calculator__form-item">
                            <div className="car__price__calculator__form-item-box">

                                <div className="select-container-box">
                                    <h3 className="select-container-title">
                                        Об'єм двигуна від
                                    </h3>
                                    <div className="select-container">
                                        <div className="option-container ">
                                            {cancelBtn(setSelectedEngineFrom)}
                                            <select value={selectedEngineFrom} onChange={selectedEngineFromChange} name="car_name"
                                                id="car_name"
                                                className="car__price__calculator-select">
                                                <option className="option">
                                                    Від
                                                </option>
                                                {
                                                    uniqueOptionsEngine
                                                }
                                            </select>

                                        </div>

                                    </div>
                                </div>
                                <div className="select-container-box">
                                    <h3 className="select-container-title">
                                        Об'єм двигуна до
                                    </h3>
                                    <div className="select-container">

                                        <div className="option-container ">
                                            {cancelBtn(setSelectedEngineTo)}
                                            <select value={selectedEngineTo} onChange={selectedEngineToChange} name="car_name"
                                                id="car_name"
                                                className="car__price__calculator-select">
                                                <option className="option">
                                                    До
                                                </option>
                                                {
                                                    uniqueOptionsEngine

                                                }
                                            </select>

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="car__price">
                        <h2 className="car__price-title">
                            Розрахувати вартість авто
                        </h2>
                        <div className="car__price-callback">
                            <h3 className="select-container-title car__price-select-title ">
                                Ваш номер телефону
                            </h3>
                            <div className="select-container car__price-select">
                                <div className="select-insert car__price-insert">
                                    <input className="select__input-insert" type="tel" id="input" maxlength="12"
                                        onChange={handlePhoneChange} onKeyDown={validateInputValueOnKeyDown}/>
                                </div>
                            </div>
                        </div>
                        <a className=" car__price-btn" onClick={sendDataOnTg}>
                            Замовити розрахунок
                        </a>
                    </div>
                </div>

            </div>
        </div>
    );


}

export default CarPriceCalculator;