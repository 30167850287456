import './car-details.css';
import Header from "../header/header";
import Footer from "../footer/footer";
import {useEffect, useState} from "react";
import ModalWidow from "../modal-widow/modal-widow";
import axios from "axios";
import {Swiper, SwiperSlide} from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';


// import required modules
import {FreeMode, Navigation, Thumbs} from 'swiper/modules';
import {useParams} from "react-router-dom";

function CarDetails({getComponent, getModalState, modalOpen}) {
    const [currentCar, setCurrentCar] = useState([]);
    const [carImgs, setCarImgs] = useState(null);
    const [thumbsSwiper, setThumbsSwiper] = useState(null);
    const {id} = useParams()


    const getCars = () => {
        axios.get(`/api/products-img/${id}/`)
            .then(function (res) {
                setCarImgs(res.data);
                console.log(res)
            })
    }

    useEffect(() => {
        getCars();
    }, [])


    const getCurrentCar = () => {
        axios.get(`/api/products/${id}?format=json`)
            .then(function (res) {
                setCurrentCar(res.data);
            })
    }
    useEffect(() => {
        window.scrollTo(0, 0);
        getCurrentCar();
    }, [carImgs]);


    const openModalWindow = () => {
        if (modalOpen) {
            return <ModalWidow getModalState={getModalState}/>
        } else {
            getModalState(false)
            return '';
        }
    }
    useEffect(() => {
        window.moveTo(0, 0);
    })
    return (
        <>
            {currentCar && carImgs &&
                <div className="car-details">
                    {openModalWindow()}
                    <div className="car-details__container container">
                        <div className="car-details__row">
                            <div className="car-details__slider-box">
                                <Swiper
                                    style={{
                                        '--swiper-navigation-color': '#fff',
                                        '--swiper-pagination-color': '#fff',
                                    }}
                                    spaceBetween={10}
                                    navigation={true}
                                    thumbs={{swiper: thumbsSwiper}}
                                    modules={[FreeMode, Navigation, Thumbs]}
                                    className="mySwiper2"
                                >
                                    {
                                        carImgs.map(carImage => {
                                            return (
                                                <SwiperSlide>
                                                    <img src={"/"+carImage.img} alt="carImg" className="car-details__img"
                                                         loading="lazy"/>
                                                </SwiperSlide>
                                            )
                                        })
                                    }

                                </Swiper>
                                <Swiper
                                    onSwiper={setThumbsSwiper}
                                    spaceBetween={20}
                                    freeMode={true}
                                    navigation={true}
                                    modules={[FreeMode, Navigation, Thumbs]}
                                    className="mySwiper"
                                    breakpoints={{
                                        // when window width is >= 640px
                                        320: {
                                            navigation: true,
                                            slidesPerView: 2,
                                        },
                                        600: {

                                            slidesPerView: 4,
                                        }
                                    }}
                                >
                                    {
                                        carImgs.map(carImage => {
                                            return (
                                                <SwiperSlide>
                                                    <img src={"/"+carImage.img} alt="carImg"
                                                         className="car-details__img car-details__img-mini"
                                                         loading="lazy"/>
                                                </SwiperSlide>
                                            )
                                        })
                                    }

                                </Swiper>


                            </div>

                            <div className="car-details__info">
                                <h2 className="car-details__title">
                                    {currentCar.name}
                                </h2>
                                <p className="car-details__price">
                                    {currentCar.price}$
                                </p>
                                <div className="car-details__characteristics-box">
                                    <div className="car-details__characteristics__item">
                                        <p className="car-details__characteristics">Пробіг: {currentCar.probig}</p>
                                        <p className="car-details__characteristics">Коробка
                                            передач: {currentCar.korobka}</p>
                                        <p className="car-details__characteristics">Тип кузова: {currentCar.kyzov} </p>
                                    </div>
                                    <div className="car-details__characteristics__item">
                                        <p className="car-details__characteristics">Рік: {currentCar.rik}</p>
                                        <p className="car-details__characteristics">Тип палива: {currentCar.typ}</p>

                                    </div>
                                </div>
                                <div className="form-button-box">
                                    <button className="form-button " onClick={() => {
                                        getModalState(true, window.location.href);
                                    }}>Купити
                                    </button>
                                </div>
                                <h3 className="car-details__description__title">
                                    Опис
                                </h3>
                                <p className="car-details__description">
                                    {currentCar.description}
                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            }
        </>

    );
}

export default CarDetails;