import './footer.css';
import telegramIcon from '../assets/icon/telegram.png';
import facebookIcon from '../assets/icon/free-icon-facebook-5968764.png';
import instagramIcon from '../assets/icon/instagram.png';
import tikTok from '../assets/icon/tik-tok.png';

const  Footer = () => {

    return (
        <div className='footer' id='footer__scroll'>
            <div className="footer__container container " >
                <div className="footer__contact-row">
                    <div className="footer__contact__item">
                        <div className="footer__contact__icon__box">
                            <svg className="footer__contact__icon footer__contact__icon-custom" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path d="M12 1c-3.148 0-6 2.553-6 5.702 0 3.148 2.602 6.907 6 12.298 3.398-5.391 6-9.15 6-12.298 0-3.149-2.851-5.702-6-5.702zm0 8c-1.105 0-2-.895-2-2s.895-2 2-2 2 .895 2 2-.895 2-2 2zm8 6h-3.135c-.385.641-.798 1.309-1.232 2p.131l.5 1h-4.264l-.344.544-.289.456h.558l.858 2h-7.488l.858-2h.479l-.289-.456-.343-.544h-2.042l-1.011-1h2.42c-.435-.691-.848-1.359-1.232-2h-3.135l-4 8h24l-4-8zm-12.794 6h-3.97l1.764-3.528 1.516 1.528h1.549l-.859 2zm8.808-2p.75l1 2h-3.892l-.858-2z"/>
                            </svg>
                        </div>

                        <div className="footer__contact-info">
                            <p className="footer__contact-title">
                                Адреси
                            </p>
                            <a href="https://goo.gl/maps/oXt8jJEeYj7waK3L6" target="_blank" className="footer__contact-info footer__adress_style">
                                <span className='footer__address-info'>м.Львів </span>
                                <span className='footer__address-info'>вул. Володимира Великого, 4 </span>
                                <span className='footer__address-info'>вул. Сяйво, 12  </span>
                                <span className='footer__address-info'>Рясне-Руська (кільцева дорога )</span>
                            </a>
                        </div>
                    </div>
                    <div className="footer__contact__item black">
                        <div className="footer__contact__icon__box">
                            <svg className="footer__contact__icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path d="M12 0c-6.627 0-12 5.373-12 12s5.373 12 12 12 12-5.373 12-12-5.373-12-12-12zm6 14h-7v-8h2v6h5v2z"/>
                            </svg>
                        </div>

                        <div className="footer__contact-info">
                            <p className="footer__contact-title">
                                Графік роботи
                            </p>
                            <p className="footer__contact-info">
                                <span>ПН-СБ: 9:00 - 19:00 </span>
                                <span>НД: Вихідний</span>
                            </p>
                        </div>
                    </div>
                    <div className="footer__contact__item black ">
                        <div className="footer__contact__icon__box">
                            <svg  className="footer__contact__icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                                <path d="M18.48 22.926l-1.193.658c-6.979 3.621-19.082-17.494-12.279-21.484l1.145-.637 3.714 6.467-1.139.632c-2.067 1.245 2.76 9.707 4.879 8.545l1.162-.642 3.711 6.461zm-9.808-22.926l-1.68.975 3.714 6.466 1.681-.975-3.715-6.466zm8.613 14.997l-1.68.975 3.714 6.467 1.681-.975-3.715-6.467z"/>
                            </svg>
                        </div>

                        <div className="footer__contact-info">
                            <p className="footer__contact-title">
                                Телефон
                            </p>
                            <p className="footer__contact-info">
                                <a className="phone__number__color" href="tel:067 380 13 00">+38 (067) 380 13 00</a>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div className="footer__map-box">
                <iframe className="footer__map"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2574.776036705506!2d24.01262345174278!3d49.809078079290884!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x473ae794c8b3d1b5%3A0xa9db3689d75d876!2z0LLRg9C70LjRhtGPINCS0L7Qu9C-0LTQuNC80LjRgNCwINCS0LXQu9C40LrQvtCz0L4sIDQsINCb0YzQstGW0LIsINCb0YzQstGW0LLRgdGM0LrQsCDQvtCx0LvQsNGB0YLRjCwgNzkwMDA!5e0!3m2!1suk!2sua!4v1670847112135!5m2!1suk!2sua"
                        allowFullScreen="" loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade">

                </iframe>
            </div>
            <div className="footer__container container footer__container-custom">

                <div className="footer__social-link-row">
                    <a href="https://instagram.com/global_car_lviv?igshid=YmMyMTA2M2Y=" target="blank" className="footer__social-link">
                        <div className="footer__social-link__item">
                            <img src={instagramIcon} alt="" className="footer__social-link-icon"/>
                            <p className="footer__social-link__name">
                                Instagram
                            </p>
                        </div>
                    </a>
                    <a href="https://www.tiktok.com/@global_car_lviv" target="blank" className="footer__social-link">
                        <div className="footer__social-link__item ">
                            <img src={tikTok} alt="" className="footer__social-link-icon"/>
                            <p  className="footer__social-link__name ">
                                Tik-tok
                            </p>
                        </div>
                    </a>
                    <a href="https://t.me/global_car_lviv" target="blank" className="footer__social-link">
                        <div className="footer__social-link__item">
                            <img src={telegramIcon} alt="" className="footer__social-link-icon"/>
                            <p className="footer__social-link__name">
                                Telegram
                            </p>
                        </div>
                    </a>

                    <a href="https://www.facebook.com/Globalcar4" target="blank" className="footer__social-link">
                        <div className="footer__social-link__item ">
                            <img src={facebookIcon} alt="" className="footer__social-link-icon"/>
                            <p  className="footer__social-link__name black">
                                Facebook
                            </p>
                        </div>
                    </a>

                </div>
            </div>
        </div>
    );
}

export default Footer;