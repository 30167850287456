import './reviews.css';
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import Review from "./review";
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Navigation } from 'swiper/modules';
import YouTube from 'react-youtube'
function Reviews() {

    const [comments, setComments] = useState([]);
    const [isPlaying, setIsPlaying] = useState(false);
    const playerRef = useRef(null);

    const getComments = () => {
        axios.get('/api/comments')
            .then(function (res) {
                setComments(res.data);
            })
    }


    useEffect(() => {
        getComments();
    }, [])



    const handlePlayPause = () => {
        if (playerRef.current) {
            if (isPlaying) {
                playerRef.current.pauseVideo();
            } else {
                playerRef.current.playVideo();
            }
            setIsPlaying(!isPlaying);
        }
    };

    // Подія відтворення змінює стан кнопки play/pause
    const handlePlayerStateChange = (event) => {
        setIsPlaying(event.data === window.YT.PlayerState.PLAYING);
    };


    return (
        <div className="reviews">
            <div className="reviews__container container">
                <h2 className="reviews__title">
                    Відгуки
                </h2>
                <div className="sliderContainer">
                    <i  className="icon-arrow-long-left review-swiper-button-prev"></i>
                    <Swiper
                        slidesPerView={3}
                        spaceBetween={30}
                        freeMode={true}
                        navigation={{
                            nextEl: '.review-swiper-button-next',
                            prevEl: '.review-swiper-button-prev',
                        }}
                        modules={[Navigation, Pagination]}
                        pagination={{
                            dynamicBullets: true,
                        }}
                        rewind={true}
                        className="mySwiper reviews__slider"
                        breakpoints={{

                            780: {

                                slidesPerView: 3,
                            },
                            // when window width is >= 768px
                            500: {
                                slidesPerView: 2,
                            },
                            320: {
                                slidesPerView: 1,
                            },
                        }}>
                        <SwiperSlide onClick={handlePlayPause} className={` ${!isPlaying ? 'reviews__slide' : ''}`}>
                            <YouTube
                                videoId={"iTNCWj_q2lk"}
                                opts={{ playerVars: { autoplay: 0 } }}
                                onReady={(event) => (playerRef.current = event.target)}
                                onStateChange={handlePlayerStateChange}
                                className="reviews__video"
                            />
                        </SwiperSlide>

                        <SwiperSlide onClick={handlePlayPause} className={` ${!isPlaying ? 'reviews__slide' : ''}`}>
                            <YouTube
                                videoId={"m2OE-WSnekI"}
                                opts={{ playerVars: { autoplay: 0 } }}
                                onReady={(event) => (playerRef.current = event.target)}
                                onStateChange={handlePlayerStateChange}
                                className="reviews__video"
                            />
                        </SwiperSlide>

                        <SwiperSlide onClick={handlePlayPause} className={` ${!isPlaying ? 'reviews__slide' : ''}`}>
                            <YouTube
                                videoId={"reYnaUyjLWQ"}
                                opts={{ playerVars: { autoplay: 0 } }}
                                onReady={(event) => (playerRef.current = event.target)}
                                onStateChange={handlePlayerStateChange}
                                className="reviews__video"
                            />
                        </SwiperSlide>

                        <SwiperSlide onClick={handlePlayPause} className={` ${!isPlaying ? 'reviews__slide' : ''}`}>
                            <YouTube
                                videoId={"e-bUFvlu1j8"}
                                opts={{ playerVars: { autoplay: 0 } }}
                                onReady={(event) => (playerRef.current = event.target)}
                                onStateChange={handlePlayerStateChange}
                                className="reviews__video"
                            />
                        </SwiperSlide>
                    </Swiper>
                    <i className="icon-arrow-long-right review-swiper-button-next"></i>
                </div>
            </div>

        </div>
    );
}

export default Reviews;