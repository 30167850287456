import "./header.css"
import logo from "../assets/img/IMG_6435.png"
import { Link } from "react-router-dom";



function Header({ getComponent, getModalState }) {

    const getEl = (e) => {
        getComponent(e.target.getAttribute('id'));
        const hamburgerMenu = document.querySelector('#menu__toggle');
        hamburgerMenu.checked = false;
    }

    return (
        <div className="header" id='header__scroll'>
            <img src={logo} alt="" className="header-logo" />
            <div className="header__container container">
                <nav className="nav">
                    <ul className="menu">
                        <li className="menu__item">
                            <Link className="menu__link" id='hero' to='/' onClick={getEl}>
                                Головна
                            </Link>
                        </li>
                        <li className="menu__item">
                            <Link to='/car' className="menu__link" id='actual-car'>
                                Автомобілі в наявності
                            </Link>
                        </li>
                        <li className="menu__item">
                            <Link to='/' className="menu__link" id='auction' onClick={getEl}>
                                Аукціони
                            </Link>
                        </li>
                        <li className="menu__item">
                            <a className="menu__link" id='footer' onClick={getEl} >
                                Контакти
                            </a>
                        </li>
                    </ul>
                </nav>
                <div className="hamburger-menu">
                    <input id="menu__toggle" type="checkbox" />
                    <label className="menu__btn" htmlFor="menu__toggle">
                        <span></span>
                    </label>
                    <ul className="menu__box">
                        <li className="menu__item">
                            <Link className="menu__link" id='hero' to='/' onClick={getEl}>
                                Головна
                            </Link>
                        </li>
                        <li className="menu__item">
                            <Link to='/car' className="menu__link" id='actual-car' onClick={getEl}>
                                Автомобілі в наявності
                            </Link>
                        </li>
                        <li className="menu__item">
                            <Link to='/' className="menu__link" id='auction' onClick={getEl}>
                                Аукціони
                            </Link>
                        </li>
                        <li className="menu__item">
                            <a className="menu__link" id='footer' onClick={getEl} >
                                Контакти
                            </a>
                        </li>
                        <a className="header-btn btn btn-adaptive" onClick={() => {
                            getModalState(true);
                        }}>
                            <p>Замовити дзвінок</p>
                        </a>
                    </ul>
                </div>
            </div>
            <a className="header-btn btn btn-not-adaptive" onClick={() => {
                getModalState(true);
            }}>
                <p className="header-btn-label">Замовити дзвінок</p>
            </a>
        </div>
    );

}

export default Header;