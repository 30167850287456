import React, {useEffect, useState} from 'react';
import Header from "../header/header";
import ModalWidow from "../modal-widow/modal-widow";
import Footer from "../footer/footer";
import "./allCar.css"
import Car from "../actual-car/car";
import axios from "axios";


function AllCarPage({getComponent, getModalState, modalOpen, getCurrentCarId}) {
    const [cars, setCars] = useState([]);
    const [sortOrder, setSortOrder] = useState("default");

    const getCars = () => {
        axios.get("/api/products").then(function (res) {
            setCars(res.data);
        });
    };

    const sortCars = () => {
        let sortedCars = [...cars];

        switch (sortOrder) {
            case "priceAscending":
                sortedCars.sort((a, b) => +a.price - +b.price);
                break;
            case "priceDescending":
                sortedCars.sort((a, b) => +b.price - +a.price);
                break;
            // Add more cases for different sorting options if needed
            default:
                // No sorting
                break;
        }

        setCars(sortedCars);
    };

    useEffect(() => {
        getCars();
        window.scrollTo(0, 0);

    }, []);

    useEffect(() => {
        sortCars();
    }, [sortOrder]);


    const handleSortChange = (event) => {
        setSortOrder(event.target.value);
    };
    const openModalWindow = () => {
        if (modalOpen) {
            return <ModalWidow getModalState={getModalState}/>
        } else {
            getModalState(false)
            return '';
        }
    }

    return (<div>
        {openModalWindow()}

        <div className="all__car__container container">
            <div className="all__car__controller">
                <div className="all__car__filter">
                    <select className="all__car__filter-select" name="filter" id="" onChange={handleSortChange}>
                        <option selected className="all__car__filter-option" value="">Сортувати по ціні</option>
                        <option className="all__car__filter-option" value="priceDescending">Спадання</option>
                        <option className="all__car__filter-option" value="priceAscending">Зростання</option>
                    </select>
                </div>
            </div>
            <div className="all__car__row">
                {cars ? cars.map(car => <Car id={car.id} car={car} getCurrentCarId={getCurrentCarId}/>) : ""}
            </div>
        </div>
    </div>);


}

export default AllCarPage;
