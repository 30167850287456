import './auctions.css'
import copart from "../assets/img/auctions-7.png";
import insurance from "../assets/img/auctions-1.png";
import insuranceCanada from "../assets/img/auctions-2.png";
import car from '../assets/img/2021-ford-f-150-tremor-front.webp'


const Auctions = () => {

    return (
        <div className="auction" id='auction__scroll' >
            <div className="auctions__container container">
                <h2 className="auctions__title">
                    Аукціони
                </h2>
                <div className="auctions__row">
                    <a href="https://www.iaai.com/" target='_blank' className='auction__item-link'>
                        <div className="auction__item">
                            <img src={insurance} alt="" className="auction-img auction-img" />
                            <h3 className="auction__item-title">
                                Insurance
                            </h3>
                        </div>
                    </a>
                    <a href="https://www.copart.com/" target='_blank' className="auction__item-link">
                        <div className="auction__item">
                            <img src={copart} alt="" className="auction-img" />
                            <h3 className="auction__item-title">
                                Copart
                            </h3>
                        </div>
                    </a>
                    <a href="https://ca.iaai.com/Search/Search/?keyword=GENESIS%20GV60#" target='_blank' className="auction__item-link">
                        <div className="auction__item">
                            <img src={insuranceCanada} alt="" className="auction-img" />
                            <h3 className="auction__item-title">
                                Insurance Canada
                            </h3>
                        </div>
                    </a>

                </div>
                <p className="auction__info">
                    Це найбільші аукціони США та Канади, викупити лот тут зможе тільки офіційний дилер. В нас є всі необхідні ліцензії для такої роботи і доступи!
                    <span> Ми зробимо це для Вас!</span>
                </p>
                <div className="auction__our__benefits">
                    <div className="auction__our__benefits-box">
                        <h3 className="auction__our__benefits__title">
                            Щоб придбати якісний транспортний засіб, спеціалісти нашої компанії беруть участь у аукціонах
                        </h3>
                        <div className="auction__our__benefits__sub-title">
                            Аукціони – торгові майданчики, на яких представлена велика кількість пропозицій з усіх
                            онлайн-ресурсів. Тут ми можемо знайти те, що ви шукаєте, оскільки маємо доступ навіть до
                            закритих торгів, а потім – доставити придбаний автомобіль з гарантією безпеки. Ми зробимо все самостійно!
                            Викупимо - доставимо - розмитнимо - при потребі, відремонтуємо.
                            Від Вас потрібно лише дотримання умов підписаного договору. Все просто 😉
                        </div>
                    </div>
                    <img src={car} alt="car" className="auction__our__benefits_car" />
                </div>
            </div>
        </div>
    );

}

export default Auctions;