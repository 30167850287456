import './callback.css'
import carIcon from "../assets/icon/car.png"

function Callback ({getModalState}) {

        return (
            <div className="callback">
                <div className="callback__container container">
                    <h2 className="callback__title">
                        Не знаєте яке авто обрати?
                    </h2>

                    <a  className="callback__btn btn" onClick={()=>{
                        getModalState(true)
                    }}>
                        <p>Замовити дзвінок</p>
                    </a>
                </div>
            </div>
        );

}

export default Callback;